@font-face {
    font-family: LuloClean;
    src: url(../fonts/Lulo.otf);
}
body{
    font-family: LuloClean !important;
    margin: 0;padding: 0;
    overflow-y: hidden;
    background: #000000 !important;
    
    
    ::-webkit-scrollbar {
        width: 5px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: Black;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: Black;
      }
    h1{
        font-size: 0.8rem;
        padding: 30px;
    }button{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        border-radius: 5px !important;
        border: 1px solid white;
        color: White;
        background-color: #000000;
    }
    
    
}
.buttons{
    border-radius: 0px !important;
    background-color: #ff0000 !important;
    color:white !important;
    font-size: 10px !important;
    padding: 8px;
}
.row {
    --bs-gutter-x: 0 !important
}
#Home{
    overflow: auto !important;
    z-index: 99;
        margin: 0;
        padding: 0;
       
        background-color: black;
        
        
    iframe{
        width: 100%;
        height: 100vh;
    }
    .ConfiguratorOptions{
        background: black ;
       

    }
    
    .LoginButton button{
        border-radius: 0px !important;
        color: white;
        background-color: #FF0000;
        cursor: pointer;
      
    }
}


#Header{
    height: 80px;
    background: black !important;
    border-bottom:2px dotted whitesmoke;
    img{
        height: 60px;
        padding: 1.5rem;
    }
}
// .............................................
.MuiAccordionSummary-content .MuiAccordionSummary-contentGutters .css-o4b71y-MuiAccordionSummary-content {
    height: 80px !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    background: black !important;
    color: white !important;
    border-bottom: 1px dotted white !important;
    height: 56px !important;
}
.css-1iji0d4{
    background: black !important;
    color: white !important;
    border-bottom: 1px dotted white !important;
    height: 56px !important;
}
.css-u7qq7e{
    background: black !important;
    color: white !important;
}
.css-15v22id-MuiAccordionDetails-root{
    background: black !important;
    color: white !important;
}
.css-i4bv87-MuiSvgIcon-root{
    color: white !important;
}
// ...........................................

#Graphics{
    // height: 500px;
    overflow: auto !important;
    z-index: 99;
}

#Logo{
    height: 500px;
    overflow: auto !important;
    z-index: 99;
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: darkgrey;
    }
    
    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    scrollbar-width: thin !important;          /* "auto" or "thin" */
    scrollbar-color: blue orange !important;   /* scroll thumb and track */ 
    input{
        margin-bottom: 10px;
    }
}
#Color{
    overflow: auto !important;
    z-index: 99;
    
}
.form-control:focus{
border-color: #FF0000 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
  

}
.form-control{
    border-radius: 0rem !important;
}

#Color{
    p{
        font-size: 12px;
    }
    height: 500px;
    .extramargin{
        margin-bottom: 2rem;
    }
    .color{
        width: 40px;
        height: 40px;
        border: 1px solid white;
        margin-bottom: 10px;
        margin: 15px;
        // border-radius: 5px;
        cursor: pointer;
        transition:0.3s;
    }
    .color:hover{
        transform: scaleY(1.6);
        transform-origin: bottom;
    }
    .British_Racing_Green{
        background-color:#004225 ; 
    }
    .Black{
        background-color: #000000;
    }
    .White{
        background-color: #fff;
    }
    .Red{
        background-color: #FF0000;
    }
    .Lime{
        background-color: #00FF00;
    }
    .Yellow{
        background-color: #FFFF00;
    }
    .Blue{
        background-color: #0000FF;
    }
    .Orange{
        background-color: #FF6600;
    }
    .Gold{
        background-color: #FFD700;
    }
    .Silver{
        background-color: #BBC2CC;
    }
    .Gun_Metal_Grey{
        background-color: #5F6670;
    }

    
}
#CustomerInfo{
    height: 500px;
   
    overflow: auto !important;
    z-index: 99;
    .color{
        width: 40px;
        height: 40px;
        border: 1px solid white;
        margin-bottom: 10px;
        margin: 15px;
        border-radius: 5px;
        cursor: pointer;
        transition:0.3s;
    }
    .color:hover{
        transform: scaleY(1.6);
        transform-origin: bottom;
    }
    .British_Racing_Green{
        background-color:#004225 ; 
    }
    .Black{
        background-color: #000000;
    }
    .White{
        background-color: #fff;
    }
    .Red{
        background-color: #FF0000;
    }
    .Lime{
        background-color: #00FF00;
    }
    .Yellow{
        background-color: #FFFF00;
    }
    .Blue{
        background-color: #0000FF;
    }
    .Orange{
        background-color: #FF6600;
    }
    .Gold{
        background-color: #FFD700;
    }
    .Silver{
        background-color: #BBC2CC;
    }
    .Gun_Metal_Grey{
        background-color: #5F6670;
    }

    .colorinfo{
        width: 40px;
        height: 40px;
        border: 1px solid white;
        margin-bottom: 10px;
        margin: 15px;
        border-radius: 5px;
        cursor: pointer;
        transition:0.3s;
    }
    .colorinfo:hover{
        transform: scaleY(1.6);
        transform-origin: bottom;
    }
    .British_Racing_Green{
        background-color:#004225 ; 
    }
    .Black{
        background-color: #000000;
    }
    .White{
        background-color: #fff;
    }
    .Red{
        background-color: #FF0000;
    }
    .Lime{
        background-color: #00FF00;
    }
    .Yellow{
        background-color: #FFFF00;
    }
    .Blue{
        background-color: #0000FF;
    }
    .Orange{
        background-color: #FF6600;
    }
    .Gold{
        background-color: #FFD700;
    }
    .Silver{
        background-color: #BBC2CC;
    }
    .Gun_Metal_Grey{
        background-color: #5F6670;
    }
    select{
        margin-bottom: 10px;
    }
    select:focus{
        border-color: #FF0000 !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6) !important;
    }
}
// -------------------------------------------------------------------------------

button#rfs-btn{
    background-color: white;
    color:black
}
span.ReactFlagsSelect-module_selectOptionValue__vS99- {
    color: black;
}
@media only screen and (max-width: 750px) and (min-width: 300px)  {
    #Dashboard tr{
        display: grid !important;
    }
    #Dashboard span{
        margin: 2px !important;
    }
    #LoggedInUserDashboard tr{
        display: grid !important;
     }
#Home{
     display: contents;
     .ConfiguratorOptions{
        height: 250px;
        overflow: auto;
     }
     
    iframe{
        height: 50vh !important;
    }
    }
    .SnapShotIcon svg{
        
        position: absolute;
        left: 44%;
        top: 92%;
    
    }
    .col-md-6.col-sm-6{
        display: grid;
    }
    .col-md-6.col-sm-6 input{
        width: 100% !important;
    }
    
}

.modal-content{
    background-color: #000000 !important;
    color:"white" !important;
}
button.btn-close {
    background-color: white;
}
#LoginModal{
    ul{
        justify-content: center;
    }
    ul li{
        list-style-type: none;
        background-color: black;
        margin: 15px;
        padding: 1rem;
        color:white;
        text-align: center;
        border-radius: 5%;
        font-size: large;
        font-weight: bold;
        cursor: pointer;
    }
    
}
#Signup{
    label{
        color:white;
        margin-bottom:1rem;
        font-weight: bold;
    }
    input{
        margin-bottom: 1rem;
    }
}
#Login{
    label{
        color:white;
        margin-bottom:1rem;
        font-weight: bold;
    }
    input{
        margin-bottom: 1rem;
    }.ErrorDialog{
        background-color: white;
        color:#ff0000;
        font-weight: bolder;
        text-align: center;
    }
}
#Dashboard{
    background-color: #f1f1f1;
    color: #ff0000;
    padding: 15px;
    h2{
        font-weight: bold;
        color: #ff0000;
    }
    button{
        border-radius: 0px !important;
        
    }
    .editButton{
        border-radius: 0px !important;
        background-color: #000000 !important;
        border: none;
    }
    .deletebutton{
        background-color: #FF0000;
        border: none;
    }
    .headerDashboard{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }span{
        background-color:#ff0000;
        color: white;
        padding: 5px;
        border-radius: 0px ;
        cursor: pointer;
        margin:8px;
        
    }
}
#Updatearea{
    background-color: #f1f1f1;
    color: #ff0000;
    padding: 15px;
    h2{
        font-weight: bold;
    }
    .editButton{
        border-radius: 0px !important;
        background-color: #000000 !important;
        border: none;
    }
    button{
        border-radius: 0px !important;
        background-color: #ff0000;
        border: none;
    }
    .headerDashboard{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    label{
        font-weight: bold;
        margin-bottom: 4px;
    }
}
#LoggedInUserDashboard{
    background-color: #f1f1f1;
    color: #ff0000;
    padding: 15px;
    h2{
        font-weight: bold;
    }
    .deletebutton{
        background-color: #FF0000;
        border-radius: 0px !important;
        border: none;
    }
    .editButton{
        border-radius: 0px !important;
        background-color: #000000 !important;
        border: none;
    }
    .headerDashboard{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    label{
        font-weight: bold;
        margin-bottom: 4px;
    }
}
.SaveConfigButton{
    border-radius: 0px !important;
}
#Monitor{
    p{
        font-size: 12px;
        margin-top: 15px;
    }
    .singleMonitor{
        // display: flex;
    align-content: center;
    // justify-content: center;
    align-items: baseline;
    margin-top: 10px;
        

    }
    img{
        width: 60%;
        cursor: pointer;
        height: 100px;
        // object-fit: contain;
        border-radius: 5px;
        
    }
   
}